import { Container } from "unstated"
import * as api from "../api"

class AccountsContainer extends Container {
    
    state = {
        isLoading: false,
        warnings: [],
        warningHeader: "",
        warningFooter: "",
        roles: [],
        accounts: [],
        editAccount: null,
        searchTerm: "",
        accountsForDeletion: null,
        phoneNumber: ""
    }

    constructor(){
        super()
        this.initialize()
    }

    initialize = async ()=>{
        if(sessionStorage.getItem("LOGGED_IN")) {
            this.getCurrentAccount()
        }
    }

    checkResult = async (result)=>{

        if(!result){
            await this.setState({
                warnings: "There was an unexpected problem. Please try again.",
                warningHeader: result?.warningHeader || "Error",
                warningFooter: result?.warningFooter ||"",
                isLoading: false
            })
            return false
        }

        if(!result?.succeeded){
            await this.setState({
                warnings: (result?.warnings && result?.warnings?.length > 0) ? result.warnings : ["There was an unexpected problem. Please try again."],
                warningHeader: result?.warningHeader || "Error",
                warningFooter: result?.warningFooter ||"",
                isLoading: false
            })
            return false
        }
        if(result?.warnings?.length > 0){
            await this.setState({
                warnings: result?.warnings ,
                warningHeader: result?.warningHeader || "Error",
                warningFooter: result?.warningFooter ||"",
                isLoading: false
            })
            return result?.succeeded
        }

        return true
    }

    updateAccount = async (data)=>{
        this.setState({
            isLoading: true
        })

        const payload = {
            brokerCompanyId: data.agencyId ? data.agencyId : data.brokerCompanyId,
            admittedProducerCode: data.admittedProducerCode,
            firstName: data.firstName,
            lastName: data.lastName,
            phoneNumber: data.phoneNumber,
            email: data.email
        }

        const result = await api.updateAccount(data.id ? data.id : data.userId, payload)
        
        if(!this.checkResult(result)){
            return
        }

        this.setState({
            isLoading: false
        })

        return result
    }

    updatePassword = async (data)=>{
        this.setState({
            isLoading: true
        })

        const payload = {
            password: data.password,
            confirmPassword: data.confirmPassword
        }

        const result = await api.updatePassword(data.id, payload)

        if(!this.checkResult(result)){
            return
        }

        this.setState({
            isLoading: false
        })

        return result
    }

    updateAccountPhoneNumber = async (phoneNumber)=>{
        this.setState({
            isLoading: true
        })

        let payload = {
            phoneNumber: phoneNumber
        }

        const result = await api.updateAccountInfo(payload)

        if(!this.checkResult(result)){
            return
        }

        if(!result?.succeeded){
            this.setState({
                isLoading: false
            })
            return result?.errorMessage || "Error"
        }

        await this.setState({
            phoneNumber: phoneNumber
        })
        
        if(!result?.succeeded){
            this.setState({
                isLoading: false
            })
            return result?.errorMessage || "Error"
        }

        this.setState({
            isLoading: false
        })
        
        return true
    }

    getCurrentAccount = async ()=>{
        this.setState({
            isLoading: true
        })
       
        const result = await api.getCurrentAccount()

        if(!this.checkResult(result)){
            return
        }
        
        this.setState({
            editAccount: result,
            isLoading: false
        })
    }
   
}

export default AccountsContainer