/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react/macro"
import React from "react"
import history from "../../components/History"
import { getSiteType } from "../../constants/SiteType"
import SelectNamedInsureds from "./SelectNamedInsureds"
import LocationBindingQuestions from "./LocationBindingQuestions"
import AbovegroundBindingQuestions from "./AbovegroundBindingQuestions"
import UndergroundBindingQuestions from "./UndergroundBindingQuestions"
import Signature from "./Signature"

const componentStyle = css`
    margin-top: 15px;

    button{
        margin-left: 0 !important;
    }

    button.float-right{
        margin-left: 20px !important;
    }
`

class Bind extends React.Component{

    state = {
        isLoaded: false
    }

   

    componentDidMount = async()=>{
        window.scrollTo(0,0)
        const { applicationsContainer } = this.props

        if(!applicationsContainer.state.currentApplicationId){
            history.push("/")
        }

        if(applicationsContainer.state.currentApplicationState === "NY"){
            await applicationsContainer.sendEmailForNewYorkDomiciledSubmission()
        }

        if(await applicationsContainer.initBindApplication()){
           await applicationsContainer.getLocationSites()
           await applicationsContainer.getSelectedNamedInsureds()
           await applicationsContainer.getBindingQuestions()
           await applicationsContainer.getTankAges()
           await applicationsContainer.getConstructions()
           await applicationsContainer.getCapacities()
           await applicationsContainer.getContents()
           await applicationsContainer.getDeductibles()
           await applicationsContainer.getPipeAges()
           await applicationsContainer.getUndergroundLeakDetectionTypes()
           await applicationsContainer.getUndergroundPipeLineConstructions()
           await applicationsContainer.getCoverageLimits()

            
            this.setState({
                isLoaded: true
            })
        }
    }

    bindApplication = async () =>{

        const { applicationsContainer } = this.props
        
        applicationsContainer.bindApplication()
    }

    downloadPolicy = ()=>{
        const { applicationsContainer } = this.props

        applicationsContainer.getPolicyPDF(applicationsContainer.state.currentApplicationId)
    }
        
    render(){
        const { applicationsContainer } = this.props

        if(!this.state.isLoaded){
            return false
        }
        
        return(
            <div css={componentStyle}>                
                    
                <div className="row">
                    <div className="col-12">
                        <h2>Bind Storage Tank Policy</h2>                        
                        

                        {(getSiteType() === "Agency" &&
                          applicationsContainer.state.currentApplicationState !== "NY") &&
                            <>
                            {!applicationsContainer.state.bound &&       
                            <p className="directions">
                                <strong>Directions</strong>: Please type your signature below to confirm that you intend to bind coverage pursuant to the terms and conditions of the quote and click the “bind” button to generate the policy which you will be able to access immediately and will also receive via email. If you do not want to bind coverage at this time or have additional questions, please click “return to homepage.” For additional assistance, please contact Benjamin Volin-Kelder at BVolin@GAIG.com or 484-379-3883".
                            </p>
                            }
                            {applicationsContainer.state.bound &&       
                                <p className="large">Congratulations! Your policy has been bound! A copy of the policy has been emailed to the agent of record. You may also access the policy by clicking "Download Your Policy" below. To navigate back to the homepage to enter another submission, please click the "Back to Homepage" button.</p>
                            }
                            </>
                        }
                    </div>
                </div>

                {applicationsContainer.state.currentApplicationState === "FL" &&
                    <div className="row">
                        <div className="col">
                            <p>The certificates requested will be sent under separate cover by a Great American storage tank underwriting contact. If you have any questions please contact Benjamin Volin-Kelder  <a href="mailto:BVolin@gaig.com">BVolin@gaig.com</a>.</p>
                        </div>
                    </div>
                }

                {(!applicationsContainer.state.bound &&
                  applicationsContainer.state.currentApplicationState !== "NY") &&
                    <>
                    {applicationsContainer.state.selectedNamedInsureds.length > 0 && 
                        <SelectNamedInsureds applicationsContainer={applicationsContainer} />
                    }
                    
                    {applicationsContainer.state.locationsBindingQuestions.length > 0 &&
                        <LocationBindingQuestions applicationsContainer={applicationsContainer} />
                    }

                    {applicationsContainer.state.abovegroundBindingQuestions.length > 0 &&
                        <AbovegroundBindingQuestions applicationsContainer={applicationsContainer} />
                    }

                    {applicationsContainer.state.undergroundBindingQuestions.length > 0 &&
                        <UndergroundBindingQuestions applicationsContainer={applicationsContainer} />
                    }
                    </>
                }
                
                {(!applicationsContainer.state.bound && getSiteType() === "Agency" &&
                  applicationsContainer.state.currentApplicationState !== "NY") &&
                    <>
                    <Signature applicationsContainer={applicationsContainer}
                               bind={true} />

                    <div className="row">
                        <div className="col-4">
                            {applicationsContainer.state.error &&
                                <div className="row margin-top">
                                    <div className="col">
                                        <p className="error">{applicationsContainer.state.error}</p>
                                    </div>
                                </div>
                            }
                        </div>                    
                    </div>
                    </>
                }

                {applicationsContainer.state.currentApplicationState === "NY" &&
                    <div className="row">
                        <div className="col">
                            <p>For New York domiciled submissions, binding and policy issuance is to be completed by the Great American storage tank underwriting team. Please send your bind request and client-signed NYFTZ1 endorsement (attached to the quote) to Benjamin <a href="mailto:BVolin@Gaig.com?subject=NYFTZ1">BVolin@Gaig.com</a>.</p>
                        </div>
                    </div>
                }

                
                          
                <div className="row button-group">
                    <div className="col-4">
                       {(!applicationsContainer.state.bound &&
                         applicationsContainer.state.currentApplicationState !== "NY") &&        
                            <button className="btn btn-sm btn-primary float-right"
                                    disabled={applicationsContainer.state.isLoading}
                                    onClick={this.bindApplication}>
                                Bind
                            </button>
                        }
                         {(applicationsContainer.state.bound && 
                           applicationsContainer.state.currentApplicationState !== "NY") &&          
                            <button className="btn btn-sm btn-primary float-right"
                                    onClick={this.downloadPolicy}
                                    disabled={applicationsContainer.state.isLoading}>
                                Download Your Policy
                            </button>
                        }
                        <button className={"btn btn-sm btn-primary" + ((applicationsContainer.state.currentApplicationState !== "NY") ? " float-right" : "")}
                                disabled={applicationsContainer.state.isLoading}
                                onClick={()=>{
                                    history.push("/")
                                }}>
                            Back To Homepage
                        </button>                  
                    </div>
                </div>
            </div>
        )
    }   
}

export default Bind