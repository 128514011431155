/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react/macro"
import React from "react"
import { formatDate } from "../../helpers/FormatDate"

const componentStyle = css`   
`
class Policy extends React.Component{

    state = {
        id: "",
        namedInsuredId: "",
        namedInsured: "",
        policyNumber: "",
        dateEffectiveUtc: "",
        dateExpirationUtc: "",
        status: "",
        type: "",
    }

    state = {
        id: ""
    }

    componentDidMount(){
        const { policy } = this.props
        
        this.setState({
            id: policy.id
        })
    }

    getPolicyPDF = ()=>{
        const { applicationsContainer } = this.props
        applicationsContainer.getPolicyPDF(this.state.id)
    }

    renewPolicy = ()=>{
        const { applicationsContainer } = this.props
        applicationsContainer.renewPolicy(this.state.id)
    }
        
    render(){
        const { policy,isLatest } = this.props
        return(
            <tr css={componentStyle}>
                <td>{policy.friendlyId}</td>
                <td>{policy.namedInsured}</td>
                <td>{policy.policyNumber}</td>
                <td>{formatDate(policy.dateEffectiveUtc)} - {formatDate(policy.dateExpirationUtc)}</td>
                <td>{policy.status}</td>
                <td>
                    <button className="anchor"
                            onClick={this.getPolicyPDF}>
                        Download
                    </button>
                </td>
                <td>
                    <button  className="anchor"
                            onClick={this.renewPolicy} disabled={!isLatest || policy.isRenewed}>
                        Renew
                    </button>
                </td>
            </tr>
        )
    }   
}

export default Policy