/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react/macro";
import React from "react";
import history from "../../components/History";
import { formatDate } from "../../helpers/FormatDate";
import ReviewApplication from "../Home/ReviewApplications";

const componentStyle = css`
  .highlighted {
    background-color: #7ee6fc;
  }
`;
class Application extends React.Component {
  state = {
    id: "",
  };

  componentDidMount = () => {
    const { application } = this.props;
    this.setState({
      id: application.id,
      state: application.namedInsuredState,
    });
  };

  continueApplication = () => {
    const { applicationsContainer } = this.props;
    applicationsContainer.continueApplication(this.state.id);
  };

  getApplicationPDF = () => {
    const { applicationsContainer } = this.props;
    applicationsContainer.getApplicationPDF(this.state.id);
  };

  bindApplication = async () => {
    const { applicationsContainer } = this.props;

    await applicationsContainer.initialize();

    await applicationsContainer.setState({
      currentApplicationId: this.state.id,
      currentApplicationState: this.state.state,
    });

    history.push("application/bind");
  };

  getComments = async () => {
    const { applicationsContainer, toggleComments } = this.props;

    toggleComments();

    applicationsContainer.getComments(this.state.id);
  };

  cloneApplication = async () => {
    const { applicationsContainer, getApplications } = this.props;
    await applicationsContainer.cloneApplication(this.state.id);
    await getApplications();
  };

  getApplication = async () => {
    const { applicationsContainer } = this.props;
    await applicationsContainer.viewApplication(this.state.id);

    applicationsContainer.setState({
      isLoading: false,
    });
    this.setState({
      showApplicationInformation: true,
    });
  };

  toggleViewApplication = () => {
    this.setState({
      showApplicationInformation: this.state.showApplicationInformation
        ? false
        : true,
    });
  };

  render() {
    const { applicationsContainer, application } = this.props;
    const curApp = applicationsContainer.state.currentApplication;

    return (
      <>
        {this.state.showApplicationInformation && (
          <ReviewApplication
            applicationContainer={applicationsContainer}
            toggleViewApplication={this.toggleViewApplication}
            curApp={curApp}
          />
        )}
        <tr css={componentStyle}>
          <td>{application.friendlyId}</td>
          <td>{application.namedInsured}</td>
          <td>{formatDate(application.dateStartedUtc)}</td>
          <td>{formatDate(application.dateSubmittedUtc)}</td>
          <td>{application.version}</td>
          <td
            className={
              application.status === "Requires additional information"
                ? "highlighted"
                : ""
            }
          >
            {application.status}
          </td>
          <td>
            {/*
                    <button className="anchor text-center"
                            onClick={this.getApplication}
                            >
                        <i className="fa fa-search"></i>
                    </button>
        */}
          </td>
          <td>
            {application.commentCount > 0 && (
              <button className="anchor" onClick={this.getComments}>
                Comments
              </button>
            )}
          </td>
          <td>
            {(application.status === "Incomplete" ||
              application.status === "Requires additional information") && (
              <button className="anchor" onClick={this.continueApplication}>
                Continue
              </button>
            )}
          </td>
          <td>
            {(application.status === "Approved" ||
              application.status === "Approved to Bind") && (
              <button className="anchor" onClick={this.getApplicationPDF}>
                View Quote
              </button>
            )}
          </td>
          <td>
            {(application.status === "Approved" ||
              application.status === "Approved to Bind") && (
              <button className="anchor" onClick={this.bindApplication}>
                Bind
              </button>
            )}
          </td>
          <td>
            {(application.status === "Approved" ||
              application.status === "Approved to Bind") && (
              <button className="anchor" onClick={this.cloneApplication}>
                Clone
              </button>
            )}
          </td>
        </tr>
      </>
    );
  }
}

export default Application;
